export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SHOW_ALERT_NOTIFICATION = "SHOW_ALERT_NOTIFICATION";

//professional 
export const START_PROFESSIONAL_ACTION = "START_PROFESSIONAL_ACTION";
export const PROFESSIONAL_ACTION_FAIL = "PROFESSIONAL_ACTION_FAIL";
export const FETCH_PROFESSIONAL_LIST_SUCCESS = "FETCH_PROFESSIONAL_LIST_SUCCESS";
export const FETCH_PROFESSIONAL_DATA_SUCCESS = "FETCH_PROFESSIONAL_DATA_SUCCESS";
//bussiness

export const START_BUSSINESS_ACTION = "START_BUSSINESS_ACTION";
export const BUSSINESS_ACTION_FAIL = "BUSSINESS_ACTION_FAIL";
export const FETCH_BUSSINESS_LIST_SUCCESS = "FETCH_BUSSINESS_LIST_SUCCESS";
export const FETCH_BUSSINESS_DATA_SUCCESS="FETCH_BUSSINESS_DATA_SUCCESS";

//category 
export const START_CATEGORY_ACTION = "START_CATEGORY_ACTION";
export const CATEGORY_ACTION_FAIL = "CATEGORY_ACTION_FAIL";
export const FETCH_CATEGORY_LIST_SUCCESS = "FETCH_CATEGORY_LIST_SUCCESS"

// sub category 
export const START_SUB_CATEGORY_ACTION = "START_SUB_CATEGORY_ACTION";
export const SUB_CATEGORY_ACTION_FAIL = "SUB_CATEGORY_ACTION_FAIL";
export const FETCH_SUB_CATEGORY_LIST_SUCCESS = "FETCH_SUB_CATEGORY_LIST_SUCCESS"
export const FETCH_ALL_SUB_CATEGORY_LIST_SUCCESS = "FETCH_ALL_SUB_CATEGORY_LIST_SUCCESS"

//sub business 
export const START_SUB_BUSINESS_ACTION = "START_SUB_BUSINESS_ACTION";
export const SUB_BUSINESS_ACTION_FAIL = "SUB_BUSINESS_ACTION_FAIL";
export const FETCH_SUB_BUSINESS_LIST_SUCCESS = "FETCH_SUB_BUSINESS_LIST_SUCCESS"
export const FETCH_SUB_BUSINESS_EXTRA_PRODUCT = "FETCH_SUB_BUSINESS_EXTRA_PRODUCT"
export const FETCH_SUB_BUSINESS_EXTRA_SERVICE = "FETCH_SUB_BUSINESS_EXTRA_SERVICE"
export const START_SUB_BUSINESS_ACTION_EXTRA_PRODUCT = "START_SUB_BUSINESS_ACTION_EXTRA_PRODUCT"
export const START_SUB_BUSINESS_ACTION_EXTRA_SERVICE = "START_SUB_BUSINESS_ACTION_EXTRA_SERVICE"
export const START_SUB_BUSINESS_ACTION_PRODUCT = "START_SUB_BUSINESS_ACTION_PRODUCT"
export const FETCH_SUB_BUSINESS_PRODUCT = "FETCH_SUB_BUSINESS_PRODUCT"
export const START_SUB_BUSINESS_ACTION_SERVICE = "START_SUB_BUSINESS_ACTION_SERVICE"
export const FETCH_SUB_BUSINESS_SERVICE = "FETCH_SUB_BUSINESS_SERVICE"
//country
export const START_COUNTRY_ACTION = "START_COUNTRY_ACTION";
export const COUNTRY_ACTION_FAIL = "COUNTRY_ACTION_FAIL";
export const FETCH_COUNTRY_LIST_SUCCESS = "FETCH_COUNTRY_LIST_SUCCESS";

//city
export const START_CITY_ACTION = "START_CITY_ACTION";
export const CITY_ACTION_FAIL = "CITY_ACTION_FAIL";
export const FETCH_CITY_LIST_SUCCESS = "FETCH_CITY_LIST_SUCCESS";

//CUSTOMER

export const FETCH_CUSTOMER_LIST_SUCCESS = "FETCH_CUSTOMER_LIST_SUCCESS"
export const START_CUSTOMER_ACTION = "START_CUSTOMER_ACTION"
export const CUSTOMER_ACTION_FAIL = "CUSTOMER_ACTION_FAIL"

//pages
export const START_PAGES_ACTION = "START_PAGES_ACTION"
export const PAGES_ACTION_FAIL = "PAGES_ACTION_FAIL"
export const FETCH_PAGES_LIST_SUCCESS = "FETCH_PAGES_LIST_SUCCESS"
export const START_PROMO_CODE = "START_PROMO_CODE"
export const FETCH_PROMO_CODE_LIST_SUCCESS = "FETCH_PROMO_CODE_LIST_SUCCESS"

//contactus
export const CONTACT_ACTION_FAIL = "CONTACT_ACTION_FAIL"
export const START_CONTACT_ACTION = "START_CONTACT_ACTION"
export const FETCH_CONTACT_LIST_SUCCESS = "FETCH_CONTACT_LIST_SUCCESS"

//subscription

export const START_SUBSCRIPTION_ACTION = "START_SUBSCRIPTION_ACTION"
export const SUBSCRIPTION_ACTION_FAIL = "SUBSCRIPTION_ACTION_FAIL"
export const FETCH_SUBSCRIPTION_LIST_SUCCESS = "FETCH_SUBSCRIPTION_LIST_SUCCESS"

//first order discount
export const START_FIRST_ORDER_DISCOUNT_ACTION = "START_FIRST_ORDER_DISCOUNT_ACTION"
export const FIRST_ORDER_DISCOUNT_ACTION_FAIL = "FIRST_ORDER_DISCOUNT_ACTION_FAIL"
export const FETCH_FIRST_ORDER_DISCOUNT_LIST_SUCCESS = "FETCH_FIRST_ORDER_DISCOUNT_LIST_SUCCESS"

//Admin User
export const START_ADMIN_USER_ACTION = "START_ADMIN_USER_ACTION"
export const ADMIN_USER_ACTION_FAIL = "ADMIN_USER_ACTION_FAIL"
export const FETCH_ADMIN_USER_LIST_SUCCESS = "FETCH_ADMIN_USER_LIST_SUCCESS"
export const FETCH_ADMIN_USER_LIST_OBJECT = "FETCH_ADMIN_USER_LIST_OBJECT"

//Order
export const START_ORDER_ACTION = "START_ORDER_ACTION"
export const ORDER_ACTION_FAIL = "ORDER_ACTION_FAIL"
export const FETCH_ORDER_LIST_SUCCESS = "FETCH_ORDER_LIST_SUCCESS"
export const FETCH_ORDER_DATA_DATA_SUCCESS = "FETCH_ORDER_DATA_DATA_SUCCESS"

// report

export const START_REPORT_ACTION = "START_REPORT_ACTION"
export const REPORT_ACTION_FAIL = "REPORT_ACTION_FAIL"
export const FETCH_REPORT_LIST_SUCCESS = "FETCH_REPORT_LIST_SUCCESS"
export const FETCH_REPORT_DATA_DATA_SUCCESS = "FETCH_REPORT_DATA_DATA_SUCCESS"

export const START_PROFESSIONAL_REPORT_ACTION = "START_PROFESSIONAL_REPORT_ACTION"
export const FETCH_PROFESSIONAL_REPORT_LIST_SUCCESS = "FETCH_PROFESSIONAL_REPORT_LIST_SUCCESS"

export const START_CUSTOMER_REPORT_ACTION = "START_CUSTOMER_REPORT_ACTION"
export const FETCH_CUSTOMER_REPORT_LIST_SUCCESS = "FETCH_CUSTOMER_REPORT_LIST_SUCCESS"